<template>
  <div class="add_class_content_sty">
    <div class="add_class_content_bg_sty">
      <img src="@/assets/img/class/jiarubanji.png" />
    </div>
    <div class="add_class_input_sty">
      <input type="text" placeholder="请输入班级邀请码" v-model="invideCode" />
      <i class="iconfont close_icon_sty" v-show="isShowClose" @click="clear"
        >&#xe628;</i
      >
      <div class="search_btn_sty" @click="getClassInfo">查找</div>
    </div>
    <div class="center_line_sty" v-show="isShowClassInfo"></div>
    <div class="class_detail_sty" v-show="isShowClassInfo">
      <div class="class_detail_left_sty">
        <img :src="classInfo.img ? classInfo.img : defaultImg" />
      </div>
      <div class="class_detail_right_sty">
        <h3>{{ classInfo.className }}</h3>
        <div>
          <div class="tearcher_header_sty">
            <img :src="classInfo.orgIcon ? classInfo.orgIcon : defaultHeader" />
          </div>
          <p>{{ classInfo.orgName }}</p>
        </div>
        <div class="totle_num_sty">共{{ classInfo.studentCount }}人</div>
      </div>
    </div>
    <div class="apply_btn_sty" v-show="isShowClassInfo">
      <div @click="close">取消</div>
      <div @click="joinClass">申请</div>
    </div>
  </div>
</template>

<script>
import { getClassInfo, joinClass } from "@/api/class";
export default {
  data() {
    return {
      invideCode: "",
      classInfo: {},
      defaultImg:require('@/assets/img/class/defaultImg.png'),
      defaultHeader:require('@/assets/img/defaultavatar.png')
    };
  },
  props: {
    isClean: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isShowClose: function () {
      if (this.invideCode.trim() == "") {
        return false;
      } else {
        return true;
      }
    },
    isShowClassInfo() {
      let list = Object.keys(this.classInfo);
      console.log(list);
      if (list.length == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    isClean: function () {
      if (!this.isClean) {
        this.invideCode = "";
        this.classInfo = {};
      }
    },
  },
  methods: {
    close() {
      this.classInfo = {};
      this.invideCode = "";
      this.$emit("close", false);
    },
    clear() {
      this.invideCode = "";
    },
    getClassInfo() {
      if (this.invideCode.trim() == "") {
        this.$message({
          type: "warning",
          message: "邀请码不能为空",
        });
        return;
      }
      this.classInfo = {};
      getClassInfo(this.invideCode).then((json) => {
        const { code, msg } = json;
        if (code === 200) {
          this.classInfo = json.data;
        } else {
          this.$message({
            type: "error",
            message: msg,
          });
        }
      });
    },
    joinClass() {
      joinClass(this.classInfo.classId).then((json) => {
        const { code, msg } = json;
        if (code === 200) {
          this.$message({
            type: "success",
            message: "申请成功,等待老师审核...",
          });
          this.close();
        }else {
            this.$message({
                type:'error',
                message:msg
            })
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.add_class_content_sty {
  width: 100%;
  .add_class_content_bg_sty {
    width: 212px;
    margin: auto;
    display: block;
  }
  .add_class_input_sty {
    width: 340px;
    margin: 0 10px;
    height: 40px;
    line-height: 40px;
    position: relative;
    margin-top: 53px;
    & > input {
      width: 264px;
      height: 40px;
      line-height: 40px;
      padding-left: 21px;
      font-size: 14px;
      border: 1px solid #eaeaea;
      border-right: none;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
    }
    .close_icon_sty {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: #bdbdbd;
      right: 87px;
      cursor: pointer;
    }
    .search_btn_sty {
      position: absolute;
      right: 0;
      background: #0d79ff;
      width: 76px;
      text-align: center;
      top: 0;
      color: #fff;
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      cursor: pointer;
    }
  }
  .center_line_sty {
    width: 400px;
    height: 1px;
    background: #eaeaea;
    margin: 27px 0 21px -20px;
  }
  .class_detail_sty {
    padding: 0 10px;
    width: 100%;
    box-sizing: border-box;
    .class_detail_left_sty {
      width: 148px;
      height: 88px;
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;
      margin-right: 15px;
      & > img {
        width: 100%;
        display: block;
      }
    }
    .class_detail_right_sty {
      display: inline-block;
      vertical-align: middle;
      & > div:nth-child(2) {
          margin: 10px 0;
        .tearcher_header_sty {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          overflow: hidden;
          display: inline-block;
          vertical-align: middle;
          margin-right: 6px;
          & > img {
            width: 100%;
            display: block;
          }
        }
        p {
          display: inline-block;
          vertical-align: middle;
        }
      }
      .totle_num_sty {
          color: #999999;
          font-size: 12px;
      }
    }
  }
}
.apply_btn_sty {
  text-align: right;
  margin-top: 26px;
  & > div {
    display: inline-block;
    cursor: pointer;
    &:first-child {
      margin-right: 32px;
    }
    &:nth-child(2) {
      width: 91px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      background: #0d79ff;
      border-radius: 18px;
      font-size: 14px;
      color: #fff;
    }
  }
}
</style>