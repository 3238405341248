<template>
  <div class="my_class_content_sty">
    <div class="my_class_title_sty">
      <p>我的班级</p>
      <div class="add_class_sty" @click="addClass" v-show="!isEmpty">
        <i class="iconfont">&#xe632;</i>
        <span> 加入班级 </span>
      </div>
    </div>
    <div class="my_class_main_content_sty">
      <one-class
        v-for="i in myClassList"
        :key="i.classId"
        :classInfo="i"
        class="one_class_sty"
        @classCurriculum="classCurriculum(i.classId, i.className)"
      />
    </div>
    <div class="turn_page_sty">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="params.pageNum"
        :page-size="params.pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
        hide-on-single-page
      >
      </el-pagination>
    </div>
    <emptyStatus
      v-show="isEmpty"
      :img="iconUrl"
      desc="赶紧加入班级吧~"
      haveButton
      buttonText="加入班级"
      @btnClick="addClass"
    />
    <el-dialog :visible.sync="dialogVisible" width="400px">
      <add-class @close="close" :isClean="dialogVisible" />
    </el-dialog>
  </div>
</template>


<script>
import oneClass from "../oneClass";
import { myClass } from "@/api/class";
import addClass from "../addClass";
import emptyStatus from "../emptyStatus";
export default {
  components: {
    oneClass,
    addClass,
    emptyStatus,
  },
  data() {
    return {
      myClassList: [],
      params: {
        pageSize: 10,
        pageNum: 1,
      },
      total: 0,
      dialogVisible: false,
      iconUrl: require("@/assets/img/class/wubanji.png"),
      isEmpty: false,
    };
  },
  created() {
    this.myClass();
  },
  methods: {
    myClass() {
      this.$loading.show();
      let that = this;
      myClass(this.params).then((json) => {
        that.$loading.hide();
        const { code, rows, total, msg } = json;
        if (code === 200) {
          this.myClassList = rows;
          if (this.myClassList.length == 0) {
            this.isEmpty = true;
          } else {
            this.isEmpty = false;
          }
          this.total = total;
        } else {
          this.$message({
            type: "error",
            message: msg,
          });
        }
      });
    },
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.myClass();
    },
    addClass() {
      this.dialogVisible = true;
    },
    // 关闭弹出层
    close(val) {
      this.dialogVisible = val;
    },
    // 去详情页面
    classCurriculum(id, className) {
      let obj = { id, className };
      this.$emit("classCurriculum", obj);
    },
  },
};
</script>

<style lang="less" scoped>
.my_class_content_sty {
  width: 100%;
  text-align: left;
  position: relative;
  height: 640px;
  cursor: pointer;
  min-height: 748px;
}
.my_class_title_sty {
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding-left: 32px;
  box-sizing: border-box;
  position: relative;
  border-bottom: 1px solid #eeeeee;
  & > p {
    font-size: 18px;
    font-weight: 500;
    color: #333;
  }
  & > .add_class_sty {
    position: absolute;
    right: 28px;
    top: 50%;
    transform: translateY(-50%);
    color: #0d79ff;
    cursor: pointer;
    & > i {
      font-size: 12px;
      color: #0d79ff;
    }
  }
}
.my_class_main_content_sty {
  margin-top: 28px;
  padding-left: 32px;
  box-sizing: border-box;
  .one_class_sty {
    margin-right: 23px;
    margin-bottom: 2px;
    &:nth-child(4n) {
      margin-right: 0px;
    }
  }
}
.turn_page_sty {
  text-align: right;
  position: absolute;
  bottom: 26px;
  right: 30px;
}
.add_class_dialog_sty {
  width: 400px;
}
</style>