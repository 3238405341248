<template>
  <div class="one_class_sty" @click="classCurriculum()">
    <div class="class_cover_sty">
      <img :src="classInfo.img ? classInfo.img : defaultImg" />
    </div>
    <div class="class_name_sty">
      {{ classInfo.className }}
    </div>
    <div class="create_auth_sty">
      <div class="auth_header_sty">
        <img :src="classInfo.orgIcon ? classInfo.orgIcon : defaultHeader" />
      </div>
      <div class="auth_name_sty">
        {{ classInfo.orgName }}
      </div>
      <div class="auth_num_sty">共{{ classInfo.studentCount }}人</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "oneClass",
  data() {
    return {
      defaultImg: require("@/assets/img/class/defaultImg.png"),
      defaultHeader: require("@/assets/img/defaultavatar.png"),
    };
  },
  props: {
    classInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    // 去详情页面
    classCurriculum() {
      this.$emit("classCurriculum");
    },
  },
};
</script>


<style lang="less" scoped>
.one_class_sty {
  width: 208px;
  height: 220px;
  display: inline-block;
  vertical-align: top;
  .class_cover_sty {
    width: 100%;
    height: 122px;
    overflow: hidden;
    border-radius: 2px;
    & > img {
      width: 100%;
      display: block;
    }
  }
  .class_name_sty {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    margin: 15px 0 11px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .create_auth_sty {
    width: 100%;
    position: relative;
    .auth_header_sty {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
      & > img {
        display: block;
        width: 100%;
        overflow: hidden;
      }
    }
    .auth_name_sty {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      color: #666;
      margin-left: 8px;
    }
    .auth_num_sty {
      position: absolute;
      right: 0;
      bottom: 0px;
    }
  }
}
</style>